import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../stores';
import HotelConfig from '../../components/Hotel/HotelConfig';
import { selectConfigAction, changeField, changeResult, updateConfigAction } from '../../stores/config';
import { ConfigImageState } from '../../types/config';
import { getHotelId } from '../../api/lib/common';
import { ResponseFailModal, ResponseSuccessModal } from '../../components/Modal/Response';

interface Props {
  isOpen: boolean;
}

const appendFormData = (formData: FormData, data: Record<string, any>) => {
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      const value = data[key] instanceof File ? data[key] : String(data[key]);
      formData.append(key, value);
    }
  });
};

const HotelConfigContainer = ({ isOpen }: Props) => {
  const dispatch = useDispatch();
  const { configInfo, configUpdateSuccess, configUpdateError, configSelectError } = useSelector(
    ({ config: { config, configUpdateSuccess, configUpdateError, configSelectError } }: RootState) => ({
      configInfo: config,
      configUpdateSuccess,
      configUpdateError,
      configSelectError,
    })
  );
  const [images, setImages] = useState<ConfigImageState>({ hotel: null, rooms: null });
  const [deleteImages, setDeleteImages] = useState({ hotel: '', rooms: '' });
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [isFailModal, setIsFailModal] = useState(false);
  const [updateMessage, setUpdateMessage] = useState('');

  const handleChangeImage = (event: ChangeEvent<HTMLInputElement>, key: keyof ConfigImageState) => {
    const file = event.target.files?.[0];
    if (file) setImages((prevImages) => ({ ...prevImages, [key]: file }));
  };

  const handleDeleteImage = (key: keyof ConfigImageState) => {
    setImages((prevImages) => ({ ...prevImages, [key]: null }));
    setDeleteImages((prevImages) => ({ ...prevImages, [key]: 'TRUE' }));

    dispatch(changeField({ key: `${key}_image`, value: null }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { value, name } = e.target;
    dispatch(changeField({ key: name, value }));
  };

  const handleUpdate = () => {
    const formData = new FormData();
    const configFields = {
      hotel_id: getHotelId(),
      id_prefix: configInfo.id_prefix,
      api_key: configInfo.api_key,
      checkinable_not_paid: configInfo.checkinable_not_paid,
      checkinable_not_assigned: configInfo.checkinable_not_assigned,
      company_id: configInfo.company_id,
      bsns_code: configInfo.bsns_code,
      property_no: configInfo.property_no,
      available_room_status: configInfo.available_room_status,
      qrcode_yn: configInfo.qrcode_yn,
      link_pms_name: configInfo.link_pms_name,
      link_code: configInfo.link_code,
      is_issued_key: configInfo.is_issued_key,
      is_ignore_paid: configInfo.is_ignore_paid,
      reservation_no_prefix: configInfo.reservation_no_prefix,
      reservation_no_postfix: configInfo.reservation_no_postfix,
      timeout: configInfo.timeout,
      latitude: configInfo.latitude,
      longitude: configInfo.longitude,
      radius: configInfo.radius,
      checkin_enable_time: configInfo.checkin_enable_time,
      customer_service_number: configInfo.customer_service_number,
      is_delete_hotel_image: images.hotel ? '' : deleteImages.hotel,
      is_delete_rooms_image: images.rooms ? '' : deleteImages.rooms,
      hotel_image: images.hotel,
      rooms_image: images.rooms,
    };

    appendFormData(formData, configFields);
    dispatch(updateConfigAction(formData));
  };

  const handleSelectConfig = useCallback(() => {
    dispatch(selectConfigAction());
    setImages({ hotel: null, rooms: null });
  }, [dispatch]);

  useEffect(() => {
    if (configUpdateError) {
      const errorMessage = configUpdateError.response ? configUpdateError.response.data.message : configUpdateError.message;
      setUpdateMessage(errorMessage);
      setIsFailModal(true);
      dispatch(changeResult({ key: 'configUpdateError', value: null }));
      return;
    }
    if (configUpdateSuccess) {
      setUpdateMessage('업데이트가 완료되었습니다.');
      setIsSuccessModal(true);
      dispatch(changeResult({ key: 'configUpdateSuccess', value: false }));
    }
  }, [configUpdateSuccess, configUpdateError, dispatch]);

  useEffect(() => {
    if (isOpen) {
      handleSelectConfig();
    }
  }, [handleSelectConfig, isOpen]);

  if (configSelectError) return <div>스마트 체크인 Config를 조회 할 수 없습니다.</div>;

  return (
    <>
      <HotelConfig
        configInfo={configInfo}
        images={images}
        handleCancel={handleSelectConfig}
        handleChange={handleChange}
        handleUpdate={handleUpdate}
        handleChangeImage={handleChangeImage}
        handleDeleteImage={handleDeleteImage}
      />
      <ResponseSuccessModal isOpen={isSuccessModal} toggle={() => setIsSuccessModal(!isSuccessModal)} message={updateMessage} />
      <ResponseFailModal isOpen={isFailModal} toggle={() => setIsFailModal(!isFailModal)} message={updateMessage} />
    </>
  );
};

export default HotelConfigContainer;
